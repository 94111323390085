.modal-content {
  border: 0;
  background-color: transparent;
  align-items: center;
  justify-content: center;
}
.modal-body {
  padding: 0px;
  background-color: transparent;
}
