
.bg{
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url(./assets/OOU_App_Background_Texture.jpg);
  width: '100%';
  height: 800px;
  background-size: cover
}
.logo {
  display: 'block';
  max-width: 20em;
  max-height:20em;
  width: 'auto';
  height: 'auto';
}